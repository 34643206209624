import React, { useEffect, useState } from "react";
import { DashboardTitle } from "../../components/DashboardTitle";
import { AccountInput } from "../../components/account/AccountInput";
import { BlackButton } from "../../components/black-button";
import { useLocation, useParams } from "react-router-dom";
import { Skeleton, TextField } from "@mui/material";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { toast } from "react-toastify";
import api from "../../api/api";
import { environment } from "../../environments/environment";
import dummyUserImage from "../../assets/dummy/dummy-user.png";
import imageUploadIcon from "../../assets/icons/image-upload.png";
import axios from "axios";
import { useSelector } from "react-redux";
import { IAppState } from "../../store/store";


// US States list for dropdown
const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut',
  'Delaware', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
  'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
  'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma',
  'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
  'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];

// Countries list with United States first
// Countries list with United States first
const countries = [
  'United States',
  'Canada',
  'Mexico',
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];



const initialValues = {
  first_name: "",
  last_name: "",
  middle_name: "",
  address_line1: "",
  address_line2: "",
  city: "",
  state: "",
  zip: "",
  country: "United States",
  phone: "",
  email: "",
};

export const EditProfile = () => {
  const { uuid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Now you can access individual query parameters
  const quantity = Number(queryParams.get("quantity"));
  const amount = Number(queryParams.get("amount"));
  const size = queryParams.get("size");

  const [profileImage, setProfileImage] = useState<any>(null);
  const [profileImageLoading, setProfileImageLoading] =
    useState<boolean>(false);

  // Function to set initial values based on user data
  const setInitialValues = () => {
    const user: any = JSON.parse(localStorage.getItem("user")!);

    // if it's only 'name' that's still stored for the user,
    // separate the first name and lastname from name
    if (!user.first_name && !user.last_name && user.name) {
      user.first_name = user.name.split(" ")[1];
      user.last_name = user.name.split(" ")[0];
    }

    return user;
  };

  // Read currency from Redux state
  // const currency = useSelector((state: IAppState) => state.shared.currency);

  const handleSubmit = async (
    values: any,
    { setSubmitting }: FormikHelpers<any>
  ) => {
    if (uuid) {
      // when the user is backing a campaign
      try {
        let payload = {
          ...values,
          quantity: quantity ?? 1,
          campaign_uuid: uuid,
          amount,
          size,
        };

        const response = await api.post(
          `${environment.baseUrl}/campaign/order`,
          payload
        );

        if (response.data.success) {
          toast.success(response.data.message);
          window.location.replace(response.data.data.payment_link);
        } else {
        }
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    } else {
      // if user is updating profile
      try {
        let payload = {
          ...values,
          media_url: profileImage,
        };

        const response = await api.put(
          `${environment.baseUrl}/profile/edit`,
          payload
        );

        if (response.data.success) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
          toast.success(response.data.message);
        } else {
        }
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("campaignToBack");

    const user: any = JSON.parse(localStorage.getItem("user")!);
    setProfileImage(user?.media_url || null);
  }, []);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      handleImage(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImage(file);
  };

  const handleImage = (file: any) => {
    // Handle image upload here
    setProfileImageLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "cqom5ijc");

    axios
      .post(`https://api.cloudinary.com/v1_1/dlicyptzz/image/upload`, formData)
      .then((response) => {
        setProfileImage(response.data.secure_url);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
      })
      .finally(() => {
        setProfileImageLoading(false);
      });
  };

  return (
    <section className={`${uuid ? "web_padding_x web_padding_y" : ""} !pt-5`}>
      <DashboardTitle
        text={uuid ? "Back Campaign" : "Edit Profile"}
      ></DashboardTitle>

      <Formik
        initialValues={setInitialValues()}
        // validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting }) => (
          <Form>
            <div className="dashboard_subtitle mt-8 md:mt-4">
              Your Information
            </div>

            <div className="grid gap-5 mt-4 md:grid-cols-2 xl:grid-cols-3">
              <div className="">
                <h6 className="font-bold text-sm md:text-base">First Name </h6>
                <Field
                  name="first_name"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="First Name...."
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Middle Name <small> (optional)</small> </h6>
                <Field
                  name="middle_name"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Middle Name...."
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Last Name </h6>
                <Field
                  name="last_name"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Last Name...."
                />
              </div>
            </div>


            <div className="grid gap-5 mt-4 md:grid-cols-2 xl:grid-cols-3">
            <div className="">
                <h6 className="font-bold text-sm md:text-base">Phone </h6>
                <Field
                  name="phone"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Phone Number...."
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Address Line 1</h6>
                <Field
                  name="address_line1"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="Street address"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Address Line 2</h6>
                <Field
                  name="address_line2"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="(optional)"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">City</h6>
                <Field
                  name="city"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="City"
                />
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">Country</h6>
                <Field
                  name="country"
                  as="select"
                  className="admin_input_1 block_input"
                  // classNamePrefix="custom_select"
                >
                  {countries.map(country => (
                    <option key={country} value={country}>{country}</option>
                  ))}
                </Field>
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">State</h6>
                <Field
                  name="state"
                  as="select"
                  className="admin_input_1 block_input"
                  // classNamePrefix="custom_select"
                >
                  <option value="">Select State</option>
                  {states.map(state => (
                    <option key={state} value={state}>{state}</option>
                  ))}
                </Field>
              </div>
              <div className="">
                <h6 className="font-bold text-sm md:text-base">ZIP/Postal Code</h6>
                <Field
                  name="zip"
                  as={TextField}
                  className="admin_input_1 block_input"
                  placeholder="ZIP Code"
                />
              </div>
              
              
            </div>

            {!uuid && (
              <>
                <div className="dashboard_subtitle mt-6 md:mt-4">
                  Profile Photo
                </div>

                <div>
                  <div
                    className="lg:w-[60%] border border-dashed border-gray-400 hover:border-black rounded-lg p-10"
                    onDragOver={(event) => event.preventDefault()}
                    onDrop={handleDrop}
                  >
                    <label
                      htmlFor="image-upload"
                      className="cursor-pointer flex flex-col items-center justify-center"
                    >
                      <img src={imageUploadIcon} alt="User" className="w-6" />
                      <p className="text-black text-center text-sm mt-3">
                        Drag your image here
                      </p>
                    </label>
                    <input
                      id="image-upload"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </div>
                  <div className="border mt-5 border-dashed border-[#BEBEBE] w-fit rounded-[27px] p-3">
                    {profileImageLoading ? (
                      <Skeleton variant="circular" width={120} height={120} />
                    ) : (
                      <img
                        src={profileImage || dummyUserImage}
                        alt="User"
                        className="rounded-full h-[120px] w-[120px]"
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            <div className="mt-5 w-full max-w-[300px] flex flex-col">
              <BlackButton
                text="Submit"
                type="submit"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              ></BlackButton>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};
